.body {
  // MUI is adding this inline, have to use !important to override.
  overflow: auto;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
}

.content {
  position: relative;
  overflow: hidden;
}
.main-content {
  background: #fafafa;
}

.footer-container {
  height: 3rem;
  bottom: 0;
  width: calc(100vw - 10rem);
  position: absolute;
}

header > div {
  justify-content: space-between;
}

.model-card {
  flex: 1;
  height: 90%; /* Adjust the height as needed */
  border-radius: 10px; /* Adjust the border radius as needed */
  margin-right: 10px; /* Adjust the margin as needed */
}

.main-card {
  flex: 3;
  background-color: #f8f9faff; /* Adjust the color as needed */
  height: 90%; /* Adjust the height as needed */
  border-radius: 10px; /* Adjust the border radius as needed */
  margin-left: 10px; /* Adjust the margin as needed */
}
.card-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  height: 100vh;
  width: 100%;
}

.logo {
  width: 36px;
  height: 36px;
}

.list-divider {
  color: red;
  margin-left: 50px;
  margin-top: 0px;
  margin-bottom: 0px;
}
